<template>
  <div>
    <div v-if="isLoaded && !incorrectDate" v-loading.fullscreen.lock="loading">
      <div class="text">
        <p>{{ strings[isgeo].question_title }}</p>
        <p>{{ birthday }}</p>
        <p>{{ strings[isgeo].question}}</p>
      </div>
      <div class="buttons">
        <el-button @click="accept(); handleClick();" class="yes">{{ strings[isgeo].yes }}</el-button>
        <el-button @click="incorrect(); handleClick();" class="no">{{ strings[isgeo].no }}</el-button>
      </div>
    </div>
    <div v-if="incorrectDate">
      <Incorrect :msg="strings[isgeo].incorrect_msg" :title="strings[isgeo].incorrect_title"></Incorrect>
    </div>
    <div v-if="correct">
      <Correct :msg="strings[isgeo].success_msg" :title="strings[isgeo].success_title" ></Correct>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import router from "../Routes";
import Incorrect from "./Incorrect";
import Correct from "./Correct";
export default {
  name: "Verify",
  components: {
    Incorrect,
    Correct
  },
  
  data() {
    return {
      isLoaded: false,
      birthday : '',
      isgeo : '',
      cust: '',
      correct: false,
      type: 2,
      incorrectDate: false,
      loading: false,
      strings : {
        ge : {
          error : 'დაფიქსირდა შეცდომა',
          question_title: 'მითითებული თარიღი',
          question: 'ემთხვევა თქვენს დაბადების თარიღს?',
          yes: 'დიახ',
          no: 'არა',
          success_title: 'დიდი მადლობა!',
          success_msg: 'თქვენ წარმატებით გაიარეთ ვერიფიკაცია',
          incorrect_title: 'მადლობას გიხდით მოწოდებული ინფორმაციისთვის!',
          incorrect_msg: 'კიდევ ერთხელ მოვახდენთ თქვენი მონაცემების გადამოწმებას',
          footer: 'როცა ფიქრობ მხოლოდ კარგზე'
        },
        en : {
          error : 'Something Went Wrong. Pleas Try Again',
          question_title: 'Is this date indicated',
          question: 'Your date of birth?',
          yes: 'Yes',
          no: 'No',
          success_title: 'THANK YOU!',
          success_msg: 'You have passed the verification successfully',
          incorrect_title: 'Thank You for the provided information!',
          incorrect_msg: 'We will review once again your personal information',
          footer: 'JUST THINK OF BEST!'
        }
      },
    };
  },
  
  methods: {
    accept() {
      this.loading = !this.loading
      this.$http.get(`/sendrequest/sendverificationaction/${this.cust}/${1}`)
        .then((r) => {
          if( r.data ) {
            this.isLoaded = !this.isLoaded;
            this.correct = !this.correct;
          } else {
            router.push('/')
          }
        })
        .catch((e) => {
          console.error(e)
        })
    },

    incorrect() {
      this.loading = !this.loading

      this.$http.get(`/sendrequest/sendverificationaction/${this.cust}/${2}`)
        .then((r) => {
          if( r.data ) {
            this.isLoaded = !this.isLoaded;
            this.incorrectDate = !this.incorrectDate;
          } else {
            router.push('/')
          }
        })
        .catch((e) => {
          console.error(e)
        })
    },
    
    handleClick() {
      this.$emit('footer');
    }
  },
  
  mounted() {
    if(!this.$route.params.id) {
      router.push('/')
    }
    
    const user_id = this.$route.params.id;
    
    this.$http.get(`/sendrequest/getverificationdata/${user_id}`)
      .then((r) => {
        if( r.data.owner ) {
          
          this.isLoaded = !this.isLoaded;
          this.cust = r.data.owner;
          this.birthday = moment(r.data.birthday).format("D/M/YYYY")
          
          if(r.data.isgeo) {
            this.isgeo    = 'ge';
          } else {
            this.isgeo    = 'en';
          }
        
          this.$emit('strings', this.strings);
          this.$emit('lang', this.isgeo);
          
        } else {
          router.push('/')
        }
      })
      .catch(() => {
        
        router.push('/')
      })
  }
}
</script>

<style scoped>

</style>