<template>
  <div class="notification success-msg">
    <p>{{ title }}</p>
    <p>{{ msg }}</p>
  </div>
</template>

<script>
export default {
  props: ['msg', 'title'],
  name: "Correct",
  
  data() {
    return {
      
    }
  },
  created() {

  }
}
</script>

<style scoped>

</style>